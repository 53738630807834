/* 
general 
-------
*/
body {
  color:#fdfdfd;
  font-size: 16px;
  line-height: 1.6;
  overflow-x: hidden;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.ant-layout {
  background: #fff !important;
}

.mainLayout .ant-layout-header {
  background: #000000;
  padding: 0;
  height: auto;
  line-height: 1.6;
}

.mainLayout .ant-layout-content {
  background: #000000;
  padding: 55px 0;
}

p {
  font-size: 16px;
  line-height: 1.6;
  color:#fdfdfd;
}

.bgGray {
  background: #000000;
}

img {
  width: 85%;
  height: 85%;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}

/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: '';
  background: #18ff80;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.header .logo {
  font-size: 22px;
  text-transform: none;
  font-weight: 400;
}

.header .logo .fas {
  color: #19c95d;
  margin: 0 5px 0 0;
  font-size: 28px;
}

.header .logo a {
  color: #fdfafa;
}

.header .ant-anchor-link-active>.ant-anchor-link-title {
  color: #19c95d;
}

.header .ant-anchor-link-title {
  color: #fdfafa;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: '';
  background: #19c95d;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
  width: 514px;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: rgb(233, 229, 229);
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: '';
  background: #19c95d;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #19c95d;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}


/* 
hero
---- 
*/
.heroBlock {
  background: url('assets/images/portrait-servers.jpg') no-repeat;
  background-position: 50% 1%;
  background-size: cover;
  height: 75vh;
}

.heroBlock .container-fluid {
  display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
  backdrop-filter: blur(1.2px);
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #1890ff !important;
}

.heroBlock .content {
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
  color: #fdfdfd; 
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
}

.heroBlock p {
  margin: 0 0 30px;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  padding: 40px 0;
  border-bottom: 1px solid #141313;
}

.featureBlock {
  background: #141313;
  text-align: center;
  color: #fdfafa;
}

.featureBlock .widthBlock {
  margin: auto;
  width: 80%;
}

.featureBlock .ant-card-head {
  background: #141313;
  color: #19c95d; 
  padding: 0 5px;
}

.featureBlock .ant-card {
  background: #141313;
  color: #fdfafa;
  padding: 0 20px;
}

/* 
about
----- 
*/
.aboutBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
}

.aboutBlock .icon {
  font-size: 40px;
  color: #1890ff;
  margin: 0 0 10px;
}

.aboutBlock .content {
  text-align: center;
}

/* 
how it works
------------
*/
.worksBlock {
  background: url('assets/images/background-clients.jpg') no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  color: #fdfafa;
  position: relative;
}

.worksBlock:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worksBlock p {
  color: #000000;
}

.worksBlock .contentHolder {
  text-align: center;
}

.worksBlock .contentHolder .ant-card {
  margin: auto;
}

/*
.worksBlock .contentHolder button {
  font-size: 40px;
  line-height: 1;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 30px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
}
*/

/* 
faqs
----
*/
.faqBlock .ant-collapse {
  margin: 0 0 40px;
  background: #000000;
  border: 0;
}

.faqBlock .ant-collapse .ant-collapse-expand-icon {
  color: #19c95d;
}

.faqBlock .ant-collapse-header-text {
  color: #fdfafa;
}

.faqBlock .ant-collapse-content {
  background: #000000;
}

.faqBlock .quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}

.faqBlock .fas {
  margin: 0 10px 0 0;
}

/* 
pricing block
-------------
*/
.pricingBlock .ant-list {
  text-align: center;
}

.pricingBlock .ant-row>div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.pricingBlock .ant-card-head-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

/* 
contact
-------
*/
.contactBlock {
  background: #141313;
}

.contactBlock .ant-form {
  max-width: 640px;
  margin: auto;
}

.contactBlock .ant-form .ant-btn-primary{
  margin: auto;
  background: #19c95d;
  border-color: #19c95d;
}

.contactBlock .ant-form .ant-input {
  background-color: #141313;
  color: #fdfafa;
}

.contactBlock .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #141313;
}

.contactBlock .ant-form .ant-input:hover {
  border-color: #19c95d;
}

.contactBlock .ant-form .ant-input::selection .ant-input:hover {
  background: #19c95d;
}

.contactBlock .ant-form .ant-input:focus, .ant-input.focused {
  border-color: #19c95d;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #000000 !important;
  color: #fdfafa !important;
  padding: 30px 0 !important;
  text-align: center;
}

.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.footer .fas {
  color: #19c95d;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fdfafa;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #19c95d;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.ant-drawer-content {
  background: #000000 !important;
}

.ant-anchor-ink-ball {
  border: 2px solid #19c95d !important;
}

.ant-drawer-content-wrapper {
  width: 256px !important;
}

.ant-drawer-content-wrapper .ant-anchor-link-active>.ant-anchor-link-title {
  color: #19c95d;
}

.ant-drawer-content-wrapper .ant-anchor-link-title {
  color: #fdfafa;
}

.ant-drawer-content-wrapper .ant-anchor-wrapper {
  background: none;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* 
responsive
----------
*/
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row>div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row>div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }
}